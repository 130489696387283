
.bm-menu {
    background-color: white;
  }
  
  .bm-item {
    color: black;
    text-align: center;
    padding: 1em 0;
    display: block;
  }
  
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: black;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: black;
  }
  
  .bm-menu-wrap {
    top: 0;
    right: 0;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .billetera{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px;
    
  }

  @media only screen and (max-width: 600px) {
    .headerT {
      height: 300px!important;
    }
  }
  .MMsubtitle{
    font-size: 12px;
    margin-top: 0px;
    padding-top: 0px;
  }

  .headerT {
    background-color: #f5f5f5;
    width: 100%;
    height: 500px;
    margin: 0px;
    background-image: url('../img/bg.jpg');
    background-size: cover;
  }
  body{
    margin: 0px;
  }

  .divTop{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    width: fit-content;
    height: fit-content;
    background-color: white;
    margin : auto; 
    text-align: center;
    margin-top: -70px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.11);
  }
  .subMenu{
   display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: auto;
    gap: 40px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 1px;
    padding-bottom:1px;
    border-radius: 20px;
    border : 1px solid #f5f5f5;

  }
  .Insidetitle{
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 25px;
    font-weight: 700;
    color: #242e30;
    padding: 0px;
    text-transform: uppercase;

  }
  .subMenu2{
    font-size: 13px;
    font-style: italic;
    margin-top: 0px;
    padding-top: 0px;
  }
  .popUp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
 
  }
  .popUpBox{
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    grid-template-rows: 15% 45% 20% 20%;
    grid-template-columns: 1fr;
    min-width: 500px;
    min-height: 400px;
    max-width: 500px;
    max-height: 90vh;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.11);
  }
  .popUpHeader{
  
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.11);
  
    padding: 0px;
    position: relative;
    
  }
  .popUpHeader{
    font-size: 20px;
    font-weight: 700;
    color: #242e30;
    text-align: center;
    padding-left:15px;
    padding-right:15px;
  }
  .popUpBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .popUpFooter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
.popUpHeader__close{
  position: absolute;
  right: 4px;
  top: 4px;
  color: black;
  border: none;
  background-color: transparent;
  cursor: pointer;
  

}
.closeBTN{
  position: absolute;
  right: 4px;
  top: 4px;
}
.imgPopUp{
  aspect-ratio: 4/3;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articulo__boton {
  padding: 10px;
  margin-top: 20px;
  width: 80%;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 20px;
  font-size: 17px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;
  font-weight: 500;
margin-bottom: 20px;
}
.articulo__boton span{
  margin-left: 20px;
}
.priceBtn{
  text-align: right;
  margin-right: 20px;
}
.productoBox{
cursor: pointer;
}
.footer{
  max-width: 100vw;
  margin-top: 40px;
  background-color: #161616;
}
.footer__container__txt{
  color: white;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  
}
.footer__container{
  color : white;
  font-size: 11px;
}
.footer__container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}
.MuiBottomNavigation-root{
  display: none;
}
 .MuiBottomNavigation-root{
    display: none;
  }


@media screen and (max-width: 768px){
  .MuiBottomNavigation-root{
    display: flex!important;
  }

 
  .stack span {
    max-width: 90vw;
}

  .footer *{
    max-width: 90vw;
    display: block;
  }
  .popUpBox{
    width: 90vw!important;
    min-width: auto!important;
    max-width: none!important;
  }
  .cartfloat {
    padding: 0px;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    right: 0px;
    top: 0px;
}
.main-checkout {
  display: block!important;
}
.boxTypes{
  display: block!important;
}
.checkout-cart.firstCH{
  border-radius: 10px!important;
  margin-bottom: 20px;
}
.css-srac2l>:not(style) {
  margin: 8px;
  width: 100%!important;
}
.MuiBottomNavigation-root {
  max-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
}

.main-checkout{
  display: grid;
  grid-template-columns: 25% 1fr;
}

.checkout-title{
  padding: 0px;
  margin: 0px;
  font-size: 20px;
  font-weight: 400;
  color: #464646;
  border-bottom: 1px solid #ebeaea;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-top:10px;
  
}
.checkout-cart{
  background-color: white;
  border-left: 1px solid #ebeaea;
  border-right: 1px solid #ebeaea;

  
}
.main-checkout{
  width: 80%;
  margin: auto;
  
}
.big-main-check{
  background-image: url('../img/bg.jpg');
  background-size: cover;
  background-position: right;
  min-height: 100vh;
}
.checkout-cart-container-in{

  padding: 10px;
}
.checkout-cart-item{
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  gap: 10px;
  margin-bottom: 20px;
}
.checkout-cart-suplement{
  display: grid;
  grid-template-columns:  1fr 20%;
  gap: 10px;
  margin-bottom: 10px;
}
.checkout-quantity{
 font-size: 14px;
 margin: auto;
 border : 1px solid #ebeaea;
 padding-left: 6px;
 padding-right: 6px;
 border-radius: 5px;
}
span.checkout-name{
  font-size: 13px;
  font-weight: 500;
  color: #2e2e2e;
}
span.checkout-price{
  font-size: 13px;
  font-weight: 600;
  color: #2e2e2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-deliv{
  display: grid;
  grid-template-columns: 1fr 10%;
  padding: 10px;
  border-bottom: 1px solid #ebeaea;
}
.checkout-deliv-price{
  font-size: 13px;
  font-weight: 600;
  color: #2e2e2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-deliv-title{
  font-size: 13px;
  font-weight: 500;
  color: #2e2e2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
.checkout-total{
  display: grid;
  grid-template-columns: 1fr 15%;
  padding: 10px;
}
.checkout-total-title{
  font-size: 16px;
  font-weight: 00;
  color: #2e2e2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-total-price{
  font-size: 16px;
  font-weight: 600;
  color: #2e2e2e;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout-button{
  width: 80%;
  height: 45px;
  background-color: white;
  color: gray;
  border: 1px solid gray;
  border-radius: 50px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;

}
.boxBtn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.checkout-button{
  display: grid;
  grid-template-columns: 20% 1fr;
  gap: 10px;
  
}
.boxBtn span{
  margin:auto;
}
.checkout-form{
 background-color: white;
}
.dadesP{
  padding-left: 30px;
}
.main-checkout {
  padding-top: 30px;
  border-radius: 10px;
}

.firstCH {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.check-af{
  margin:20px;
}

.boxSep{
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}
.centerM{
  margin-top: 20px!important;

}
.title100{
  margin-top: 20px!important;
  width: fit-content!important;
}
.enviamentBox{
  width: fit-content!important;
}
.observ {
  width: 93%!important;
}
.boxType{
  cursor: pointer;
  margin-top: 20px;
  min-width: 25%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70% 1fr;
  border: 1px solid #ebeaea;
  padding: 10px;
  border-radius: 10px;

  gap: 10px;
  margin-bottom: 20px;
  
}
.boxTypeImg img{
max-height: 80px;
}
.boxTypeImg{
  text-align: center;
  width: 100%;
  height: 100%;
  
  object-fit: cover;
  border-radius: 10px;
}
.boxTypeTitle{
 text-align: center;

}
.selectedType{
  border: 1px solid #d6f816;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px #d6f81657;
}
.boxTypes{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}
.disabledType{
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed!important;
}
.btn-checkout{
  height: 45px;
  background-color: black;
  color: white  ;
  border-radius: 50px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.listamenu {
  font-size: 12px;
  font-weight: 400;
  color: #646464;
  font-style: italic;
}

.btn-checkout{
margin-bottom: 20px;
}

.felPremi{
  font-style: italic;
  font-size: 11px;
  text-align: center;
  font-weight: 500;
}
.tdDisabled{
  opacity: 0.5;
}
a.btnOpinio {
  background-color: #000000d6;
  padding: 5px 15px 5px 15px;
  color: white;
  cursor: pointer;
}
.spanAlert {
  font-size: 12px;
  padding: 5px;
}