@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a {
  color: #000000;
  text-decoration: none;
}

.MuiDrawer-paper {
  background-color: #f0f0f0;
  width: 240px;
}

:root {
  font-family: 'Poppins', sans-serif !important;
}

.menu-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  text-align: center;

}

.listMenuItem * {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
}

.listadoMenu {
  height: 100%;

  align-items: center;
  display: flex;
  flex-direction: column;
}

.sign-out-item {
  margin-top: auto !important;

}

.menu-container {
  display: flex;
}

.content {
  flex: 1;
  margin-left: 20px;
}

body {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  background-color: #fcfcfc;
}

h2 {
  font-weight: 700;
  font-size: 52px;
  color: #000000;
  letter-spacing: -1px;
}

h3 {
  font-size: 30px;
  color: #000000 !important;
}

.boxTitulo {
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.title {
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  color: #000000 !important;
  letter-spacing: -1px;
}

.subtitle {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  font-size: 16px;

  font-weight: 400;
  color: #2e2e2e !important;
}

.articulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  border: 1px solid #ebeaea;
  border-radius: 0.5rem;
  max-width: 550px;
}

.articulo__imagen {
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.articulo__imagen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.articulo__descripcion {
  flex: 1;
  margin: 0 1rem;
}

.articulo__descripcion h3 {
  margin: 0;
  font-size: 1.5rem;
}

.articulo__descripcion p {
  margin: 0;
}

.articulo__precio {
  font-size: 13px;
  font-weight: 700;
  background-color: #f5f3f1;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 15px;
  color: #242e30;
}

.listado {
  width: 80%;
  margin: 0 auto;
}

.logoRS {
  width: 40px;
  height: 40px;
  margin-top: 20px;
  text-align: center;
}

.RCTEXT {
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;
}

.articulo__cantidad {
  display: flex;
  align-items: center;
  justify-content: center;
}

.articulo__cantidad-boton {
  width: 2rem;
  height: 2rem;
  border-width: 1px;
  border-radius: 50%;
  border-color: black;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  /* verde */
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: all 0.3s ease;
}

.articulo__cantidad-boton:hover {
  background-color: rgba(128, 128, 128, 0.404);
  /* verde oscuro */
}

.articulo__cantidad-boton:active {
  transform: scale(0.95);
}

.articulo__cantidad-input {
  width: 3rem;
  margin: 0 0.5rem;
  text-align: center;
  border: solid 1px #ccc;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  font-weight: 700;
  background-color: #f2f2f2;
  /* gris claro */
}

.articulo__cantidad-input:focus {
  border: solid 1px gray;
  /* verde */



}

.comandes {
  padding-top: 20px;
}

.okSub {
  color: rgb(165, 165, 165);
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.okTitle {
  color: #82d982f0;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.koTitle {
  color: #D95E52;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0px;
  padding: 0px;
}

.empresasNombre {
  color: black;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}

.empresasTrabajadores {
  color: black;
  font-size: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
}

.empresasNombreT {
  color: black;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0px;
  margin: 0px;
}

.boxEmpresa .selectempresa {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background-color: #1e85a4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.boxTrabajadores {
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  margin-top: 20px;

}

.boxEmpresas {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 20px;

}

.boxEmpresa {
  cursor: pointer;
  min-width: 200px;
  min-height: 200px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;

}

.boxGeneral {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 auto;
}

.cart {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  height: fit-content;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;


}

.productesRow {
  font-size: 11px;
}

.ml8 {
  margin-left: 8px;
}

.cartfloat {
  min-width: 100px;

  width: fit-content;
  height: fit-content;
  position: fixed;
  bottom: 0px;
  right: 10px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}


.titlecart {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', Times, serif;
  font-weight: 15px;
  font-size: 1.4rem;
}

.listacomandapadre {
  padding: 0px 10px 0 10px;
  margin-right: 10px;
  margin-left: 10px;

}

.listacomanda {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 10px 5px 10px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;


}

.buttonscart {
  display: flex;
  align-items: center;
  justify-content: center;
}


.botonsuma {
  margin: 0px 5px 0px 5px;
  width: 30px;
  height: 30px;
  background-color: #ffffff00;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;

}

.switcherTransp {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  width: 80%;
  background-color: #efedea;
  border-radius: 20px;
  height: 45px;
}

.selectedRecollida {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  transition: all 0.2s ease-in-out;
}

.listacomandapadre {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.listacomandapadre::-webkit-scrollbar {
  width: 2px;
  background: rgb(255, 255, 255);
  /* make scrollbar transparent */
}

.listacomandapadre::-webkit-scrollbar-thumb {
  background: black;
}

.switcherTransp div {

  cursor: pointer;
  font-size: 14px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonresta {

  width: 30px;
  height: 30px;
  background-color: #ffffff00;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.botonborrar {
  width: 30px;
  height: 30px;
  font-size: 21px;
  font-weight: 300;
  color: rgb(201, 12, 12);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxLoader {
  background-color: white;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0rem 0.625rem rgb(90 97 105 / 12%);
  border-radius: 25px;
  padding: 25px;
}

.loadPadre {
  height: 100vh;
  width: 100vw;
  background-image: url("https://feslatevacomanda.elracodelacigonya.com/fotos/plat.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;



}

.quesBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


}


.preciofinal {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preciofinalnumero {
  font-weight: 400;
  margin-left: 5px;

}

.prova {
  font-size: 1rem;
  margin-left: 5px;
  margin-right: 5px;
}

li.listacomanda {
  border-bottom: 1px solid rgba(204, 204, 204, 0.562);
}

li.listacomanda:first-child {
  border-top: 1px solid rgba(204, 204, 204, 0.562);
}

.quant {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  text-align: center;
  font-size: 13px;
}

.finComadaParent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;


}

.quesBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 20%;
  margin: 0 auto;
}

.prova {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 100%;

}

.enviamentT {
  font-size: 0.9rem;
  font-weight: 400;
}

.enviamentP {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: right;
}

.fincomanda {
  width: 80%;
  height: 45px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins', Times, serif;


}

.aFinComanda {
  width: 100%;
}

.cartFloatFooterSub {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6px;

}

.cartFloatFoote {
  width: 80%;
}

/* Checkout styles */
.subtotalP {
  text-align: right;
}

.subTotalT {
  text-align: left;
  font-size: 1rem;
}

.subTotalTP {
  text-align: right;
  font-size: 1rem;
}

.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkoutTitle {
  font-size: 69px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkout-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divTop {
  margin-bottom: 20px !important;
  padding-bottom: 10px;
}


.checkout-table {
  width: 80%;
  margin-top: 20px;
  border-collapse: collapse;
}

.checkout-table thead th {
  border-bottom: 2px solid black;
  padding: 10px;
}

.checkout-table tbody td {
  border-bottom: 1px solid lightgray;
  padding: 10px;
}

.tCenter {
  text-align: center;
}

.tipusPlatMenu {
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  gap: 10px;
  font-size: 17px;
  padding-top: 25px;
}

.tipusPlatMenu::before,
.tipusPlatMenu::after {
  content: "";
  height: 0;
  border-top: 1px solid #ebeaea;
  flex-grow: 1;
  margin: 0 5px;

}

.tipusPlatMenu::before {
  margin-left: 0;
  width: calc(32% - 15px - 0.5em);
  /* Calcula la mitad del espacio restante después de restar el margen, el padding y el ancho del texto */
}

.tipusPlatMenu::after {
  margin-right: 0;
  width: calc(32% - 15px - 0.5em);
}

.total-price-container {
  margin-top: 20px;
  margin-right: 95px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  width: 35%;
  font-weight: bold;
}

.text-total {
  font-size: 30px;
  margin: 0px;
}

.final-price {
  padding-left: 8px;
  font-size: 30px;

  margin: 0px;
  font-family: 'Poppins', Times, serif
}



/* Checkout styles */

.checkLayout {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;

}

.inputCheckout {
  width: fit-content;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 15px;
  font-style: italic;
}

.inputCheckoutbig {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-style: italic;
}

.form-info {
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
}

.paymentmethod {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 10px;
  gap: 20px;
}

.payment-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(231, 231, 231);
  border-radius: 5px;

}

.delivery-method {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  gap: 20px;
}

.checkout-hellegit-until-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.make-payment-button {
  width: 100%;
  height: 40px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
  margin-left: 25px;
  font-family: 'Poppins', Times, serif;
}

.material-symbols-outlined {
  float: left;
}

.iconTotal {
  float: right;
  font-size: 12px;
}

.titlecart {
  cursor: pointer;
}

span.iconTotal {
  width: 15px;
  height: 15px;
  background-color: black;
  color: white;
  border-radius: 50%;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
  margin-top: -4px;
}

.tipoR {
  font-size: 14px;
  font-weight: 400;
  color: rgb(119, 119, 119);
}

.spanTop {
  font-size: 14px;
  font-weight: 400;
  color: rgb(119, 119, 119);
  margin-top: 0px;
  padding-bottom: 10px;


}

.bgBox {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;

}

.okSubBox {

  border-top: 1px solid #e5e5e5;
  margin-top: 40px;
  padding-top: 10px;
  max-width: 60%;
}

.okButton {
  margin-top: 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

}

.boxMiddle {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  width: fit-content;
  padding: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);


}

.boxMani {
  padding-top: 50px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.titleInsideInside {
  padding-top: 5px;
  font-size: 18px !important;
  font-weight: 600;
  padding-bottom: 5px;
  color: #242e30 !important;
  text-transform: uppercase;
}

.subtitleInsideInside {

  font-size: 16px !important;
  font-weight: 400;
  padding-bottom: 20px;
  color: #242e30 !important;
}

.imgProduct {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  object-position: center;
}

.selected {
  color: red;
}

.hedaerTopB {
  text-align: center;
}

.txtC {
  text-align: center;
}

.formBox {
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  gap: 20px;
  border: 1px solid #ccc;
  padding: 20px;

}

.formBox p {
  margin: 0px;
  padding: 0px;
}

.boxMenu {
  border: 1px solid #ccc;
  padding: 20px;
}

.boxMenus {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

/* Administrador de menu y platos */

.headerTopB {
  text-align: center;
  font-family: Playfair Display;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.txtC {
  font-family: Playfair Display;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.formBox {
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 30px;
  width: 70%;
}

.divDescripcionSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.Textos {
  font-size: 20px;
  font-weight: 300;
  text-align: center;

}

.placeholderCrearMenu {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
  width: 96%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btnCrear {
  width: 100%;
  font-size: 18px;
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
  transition: 0.4s all;
  border-width: 1px;
}

.btnCrear:hover {
  background-color: white;
  color: black;
}

/*Lista de menus*/

.boxMenu {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.tituloMenu {
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid #ccc;
  font-style: italic;
  margin-bottom: 10px;
  text-align: center;
}

.caracteristicasMenu {
  font-weight: 700;
}

.flexmenusfets {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}

.btnMenusfets {
  width: 100%;
  font-size: 18px;
  background-color: black;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 4px 30px 4px 30px;
  cursor: pointer;
  transition: 0.4s all;
  border-width: 1px;
}

.btnMenusfets:hover {
  background-color: white;
  color: black;
}

.bgLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

}

.loaderBoxInside {
  width: fit-content;
  height: fit-content;
}

.loaderS {
  height: 200px;
  width: fit-content;
}

.cartfloat {
  width: 20vw;

}

.cartFloatTitle {
  font-size: 1.75rem;
  font-weight: 600;
  text-align: left;
}

.cartFloatHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.562);
}

.cartFloatBody {
  padding: 10px;
}

.platosMenuBox {
  display: grid !important;
  grid-template-columns: 1fr !important;
  grid-template-rows: 1fr 1fr 1fr !important;
  gap: 10px;
}

.alergens {
  align-items: flex-start;
  background-color: transparent;
  border: none;
  color: #242e30;
  display: flex;
  font-family: JetSansDigital, Arial, sans-serif;
  font-size: 14px;
  font-size: .875rem;
  font-weight: 400;
  justify-content: left;
  line-height: 1.43;
  margin: 8px 0 0;
  padding: 16px 0 8px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.defInp {
  --Input-radius: 8px;
  --Input-gap: 0.5rem;
  --Input-placeholderColor: inherit;
  --Input-placeholderOpacity: 0.5;
  --Input-focusedThickness: 2px;
  --Input-focusedHighlight: #096BDE;
  --Input-minHeight: 2.5rem;
  --Input-paddingInline: 0.75rem;
  --Input-decorator-childHeight: min(2rem, var(--Input-minHeight));
  --Icon-fontSize: 1.5rem;
  --Input-decorator-childOffset: min(calc(var(--Input-paddingInline) - (var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) - var(--Input-decorator-childHeight)) / 2), var(--Input-paddingInline));
  --_Input-paddingBlock: max((var(--Input-minHeight) - 2 * var(--variant-borderWidth, 0px) - var(--Input-decorator-childHeight)) / 2, 0px);
  --Input-decorator-childRadius: max(var(--Input-radius) - var(--variant-borderWidth, 0px) - var(--_Input-paddingBlock), min(var(--_Input-paddingBlock) + var(--variant-borderWidth, 0px), var(--Input-radius) / 2));
  --Button-minHeight: var(--Input-decorator-childHeight);
  --IconButton-size: var(--Input-decorator-childHeight);
  --Button-radius: var(--Input-decorator-childRadius);
  --IconButton-radius: var(--Input-decorator-childRadius);
  box-sizing: border-box;
  min-width: 0;
  min-height: var(--Input-minHeight);
  cursor: text;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-inline: var(--Input-paddingInline);
  border-radius: var(--Input-radius);
  font-family: "Public Sans", var(--joy-fontFamily-fallback);
  font-size: 1rem;
  --variant-borderWidth: 1px;
  color: var(--joy-palette-neutral-outlinedColor);
  border: var(--variant-borderWidth) solid;
  border-color: var(--joy-palette-neutral-outlinedBorder);
  background-color: var(--joy-palette-common-white);
}

.comandes {
  width: 95%;
  margin: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table {
  font-size: 13px;
}

.proximitat {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
}

.popUpBody2 {
  padding: 20px;
}

.popUpBody2Box p {
  font-size: 15px;
  margin: 0px;
}

.popUpBody2Box strong {
  font-size: 17px;
}

.popUpBody2Box {
  padding-top: 10px;
}

#redsysForm {
  display: none;
}

.boxGen {
  width: 550px;
}

h2.Insidetitle {
  cursor: pointer;
}

.boxGen {
  margin-top: 20px;

  border: 1px solid whitesmoke;
  background-color: white;
  padding-left: 14px;
  padding-right: 20px;
  border-radius: 20px;
}

a.linkMenu {
  width: 100%;
}


.chipDel {
  background-color: #ffe9e8;
  color: #a10e25 !important;
  margin-bottom: 10px;
}

h2.Insidetitle {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.subMenu {
  background-color: white;
}

.subMenu p {
  cursor: pointer;
}



/* Mobile */
.boxGen {
  max-width: 90vw;
}

.menu-drawer {
  border: 1px solid #ccc;
}

.menu-drawer {
  border-right: 1px solid #dcdcdc;
  box-shadow: none;
}

.menu-title {
  padding: 10px;
}

.sign-out-item {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.menu-drawer {
  width: 240px;
}

.OrderTableContainer {
  width: calc(100% - 12px) !important;
}

.SearchAndFilters-tabletUp {
  width: calc(100% - 12px) !important;
}



.MuiAvatar-root {
  background-color: #e1e1e1 !important;
}

td.error div {
  background-color: #ffe9e8;
}

td.pagat div {
  background-color: #d7f5dd;
}

td.pendent div {
  background-color: #d8d8d8;
}

.minIDP {
  font-size: 10px;
  margin-top: 0px !important;
  padding-top: 4px !important;
}

.elimBTN {
  margin-bottom: 20px;
}

.gridPlats {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;

}

.selectTipusPlat * {
  background-color: white !important;
}

.modalPlat {
  background-color: white !important;
}

.tipusPlat {
  font-size: 12px !important;
  text-align: right;
  color: white !important;
  background-color: #242e30;
  width: fit-content;
  padding: 5px 10px 5px 10px;
  border-radius: 15px;
  position: absolute;
  top: 5px;
  left: 5px;

}

.titleBox {
  min-height: 50px;
  padding-top: 25px;


}


.minPerc {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.titleBox2 {
  min-height: 30px;
  padding-top: 25px;


}

.uploadedTxt {
  font-size: 12px !important;
  color: #242e30 !important;
  font-style: italic;
  margin-top: 5px;
}

.chipADD {
  cursor: pointer;
  background-color: #d7f5dd !important;
  color: #1a5a1a !important;
  margin-bottom: 10px;
}


.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-modal-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 16px;
  max-width: 400px;
  max-height: 90vh;
  overflow: auto;
}

.pdfBtn {

  color: black;
  padding: 10px;
  border-radius: 10px;
  margin-top: 5px;
  border: none;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  margin: auto;
  transition: all 0.5s ease-in-out;
  background-color: rgb(245, 245, 245);
}

.pdfBtn:hover {
  background-color: #e1e1e1;
}

.pdfBtnn {
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  border: none;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  margin: auto;
}

.inputPDF {
  margin-bottom: 20px;
}

.inputPDF p {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-left: 5px;
}

.inputPDF input {
  width: fit-content;
  min-width: 25vw;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  margin-top: 10px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

.sendPDF {
  cursor: pointer;
}

.checkboxes {
  display: block;

}

.nomg {
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: 20px !important;
}

.minim {
  font-size: 10px;
  margin-left: 5px;
}

.checkout-cart-suplement-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}


.cartaWebTxt {
  font-size: 14px;
}

.stepTxt {
  font-size: 0.8rem;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}


.firstAsk {
  padding-top: 25px !important;
}

.lastAsk {
  padding-bottom: 25px !important;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}

.stepBtnBox {
  margin-top: auto;
}

.stepContentBox {
  display: inline-block;
}

.mainBoxSteps {
  display: flex;
  flex-direction: column;

}

.stepBox {

  padding: 20px;
  width: fit-content;
  height: fit-content;
  min-width: 80vw;
  min-height: 60vh;
  border-radius: 20px;
  box-shadow: 0 0 120px 0 rgba(0, 0, 0, 0.1);
}

.infoPers {
  font-size: 0.8rem;
  font-weight: 400;
  color: #000;

}

@media only screen and (min-width: 768px) {
  .boxInfoPersonal1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .stepBtnBox {
    padding-bottom: 80px;
  }

  .titleOpinansM {
    font-size: 40px !important;
    text-align: center;
    margin-top: 20px !important;

  }
  .stepBox {
    margin-left: 15px!important;
    margin-right: 15px!important;
    margin-bottom: 10px;
    height: auto;
  }


}

.thxBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #f5f5f5;
}

.imgC {
  display: flex;
  flex-direction: column;
  align-items: center;
}